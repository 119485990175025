import React, {useMemo} from 'react';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Trans} from 'react-i18next';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'tamagui';

import {
  IconView,
  useAppConfig,
  Typography,
  useAppStructure,
  getServicePageDefaults,
  AppServicePage,
  useAppletsList,
  getLocalizedText,
} from '@medad-sep/core';

import {HighlightPagesCount} from '../../../../../config/defaultLauncherPages';

import {AllServices} from './screens/AllServices';

const MainTabNavigator = createBottomTabNavigator();

export const Main = () => {
  const applets = useAppletsList();
  const appStructure = useAppStructure();
  const appConfig = useAppConfig();
  const {background} = useTheme();
  const {t, i18n} = useTranslation();

  const activeColor = appConfig.accentColor as any;

  const tabs = useMemo(() => {
    return appStructure.pages
      .slice(0, HighlightPagesCount)
      .reverse()
      .reduce(
        (acc, page) => {
          const service = getServicePageDefaults(page as AppServicePage, applets);

          if (service) {
            const tab = {
              id: service.id,
              title: getLocalizedText({value: service.name, t, i18n}),
              icon: service.Icon,
              Component: service.Component,
              options: {headerShown: false},
            };

            acc.unshift(tab as any);
          }

          return acc;
        },
        [AllServices],
      );
  }, [appStructure.pages, applets]);

  const getTabBarIcon = (focused: boolean, routeName: string) => {
    const icon = tabs.find((p) => p.id === routeName)?.icon;
    return <IconView icon={icon} color={focused ? activeColor : '$neutral-600'} size={22} />;
  };

  const getTabBarLabel = (focused: boolean, routeName: string) => {
    return (
      <Typography marginBottom={2} variant="caption" color={focused ? activeColor : '$textColor'}>
        <Trans i18nKey={(tabs.find((p) => p.id === routeName)?.title as string) || ''} />
      </Typography>
    );
  };

  return (
    <MainTabNavigator.Navigator
      screenOptions={({route}) => ({
        tabBarStyle: {
          height: 64,
          backgroundColor: background.val,
          borderTopWidth: 0,
          shadowColor: 'transparent',
          paddingTop: 8,
        },
        tabBarActiveTintColor: activeColor,
        tabBarIcon: ({focused}) => getTabBarIcon(focused, route.name),
        tabBarLabel: ({focused}) => getTabBarLabel(focused, route.name),
        tabBarLabelPosition: 'below-icon',
      })}>
      {tabs.map(({id, Component, options, title}) => (
        <MainTabNavigator.Screen
          initialParams={{title: title, isTabScreen: true}}
          key={id}
          name={id}
          options={options}
          component={Component}
        />
      ))}
    </MainTabNavigator.Navigator>
  );
};
