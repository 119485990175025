export const ar = {
  home: 'الرئيسية',
  profiles: 'الحسابات',
  default: 'الأساسي',
  settings: {
    title: 'إعدادات',
    notice: 'تنويه',
    restartNow: 'إعادة التشغيل',
    switchNow: 'تغيير الان',
    setDefault: ' و ضع الأساسي',
    switchAccount: 'تغيير الحساب',
    logOut: 'تسجيل الخروج',
    later: 'لاحقاً',
    category: {
      app: 'إعدادات التطبيق',
      service: 'إعدادات الخدمات',
      help: 'مساعدة و دعم',
      privacy: 'الخصوصية',
    },
    privacyPolicy: 'سياسة الخصوصية',
    scheduleSetting: 'ضبط الجدول الزمني',
    termsAndConditions: 'الأحكام والشروط',
    connectWithUs: 'اتصل بنا',
    language: 'اللغة',
    darkMode: 'الوضع المظلم',
    userProfile: {
      title: 'حساب المستخدم',
      userName: 'اسم المستخدم',
      email: 'البريد الإلكتروني',
      firstName: 'الاسم الأول',
      lastName: 'اسم العائلة',
    },
    editHomeWidget: {
      label: 'تحرير القطعة الرئيسية',
      title: 'قم بتحديد وترتيب عناصر واجهة المستخدم التي تريد ظهورها في الصفحة الرئيسية',
    },
    calendar: {
      syncEvents: 'مزامنة أحداث التقويم',
      displayEvents: 'عرض أحداث التقويم',
    },
  },
  myTools: {
    title: 'أدواتي',
    category: 'الفئة',
    allCategories: 'جميع الفئات',
    noTools: 'لا توجد أدوات',
    servicesList: 'قائمة الخدمات',
    searchByApplicationName: 'ابحث بواسطة اسم التطبيق',
    openInWebView: 'فتح في نافذة جديدة',
    openInNewTab: 'فتح في نافذة جديدة في المتصفح',
  },
  dashboard: {
    save: 'حفظ',
    personalize: 'تعديل',
    insertRow: 'أدخل صفًا جديدًا',
    addWidget: 'إضافة',
    allWidgets: 'كل القطع',
  },
  timeLimit: {
    cancel: 'الغاء',
    logout: 'تسجيل الخروج',
    warningTitle: 'تحذير انتهاء مهلة الجلسة',
    warningMsg: 'ستنتهي جلستك قريبًا. هل ترغب في البقاء قيد تسجيل الدخول؟',
  },
  launcher: {
    sideBarTitle: 'الخدمات',
    home: {
      title: 'الرئيسية',
    },
    notifications: {
      title: 'اشعارات',
    },
    _: '// todo remove and move to applet-core: editHomeWidget, calendar, myTools, dashboard',
    dashboard: 'لوحة التحكم',
    allServices: {
      searchInput: 'ابحث عن خدمة ...',
      title: 'الخدمات',
      numOfServices: 'عدد الخدمات {{count}}',
    },
    calendar: {
      title: 'التقويم',
    },
  },
  seeMore: 'شاهد المزيد',
  hi: 'مرحبا',
};
