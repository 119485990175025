import React, {useEffect, useState} from 'react';

import {
  AppEvents,
  Pressable,
  ShellTrans,
  Typography,
  useAppEventHub,
  useAppletsList,
  useAppStructure,
  useAppTheme,
  XStack,
  YStack,
  BidirectionalIcon,
} from '@medad-sep/core';

import {LauncherPage} from '../../../../../models/LauncherPage';

import {useMenuItems} from './hooks/useMenuItems';

export type PagesItems = Omit<LauncherPage, 'Component'>[];

interface Props {
  highlightCurrentPage?: boolean;
}

export const SideBarMenu = ({highlightCurrentPage}: Props) => {
  const appStructure = useAppStructure();
  const applets = useAppletsList();
  const appEventHub = useAppEventHub();
  const {theme} = useAppTheme();
  const [currentPage, setCurrentPage] = useState<string | undefined>();
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(
    (() => {
      if (!highlightCurrentPage) return;
      return appEventHub?.addListener(AppEvents.ROUTE, (args) => {
        setCurrentPage(args);
      });
    }) as any,
    [],
  );

  const menuItems = useMenuItems({
    appStructure,
    applets,
    currentPage,
    isExpanded,
  });

  return (
    <YStack
      flex={1}
      backgroundColor={theme === 'dark' ? '#242526' : '$accent'}
      justifyContent="space-between"
      width={isExpanded ? '$20' : '$6'}
      height="100%"
      paddingVertical="$20"
      overflow="scroll"
      paddingHorizontal={isExpanded ? '$4' : '$0'}>
      <YStack gap="$24">
        <XStack alignItems="center" justifyContent={isExpanded ? 'space-between' : 'center'} paddingHorizontal="$16">
          {isExpanded && (
            <Typography color="white" fontWeight="600">
              <ShellTrans i18nKey="launcher.sideBarTitle" />
            </Typography>
          )}
          <Pressable alignItems="center" justifyContent="center" onPress={() => setIsExpanded(!isExpanded)}>
            <BidirectionalIcon key="icon" icon={isExpanded ? 'ArrowLeftCircle' : 'List'} size={24} color="white" />
          </Pressable>
        </XStack>
        <YStack marginBottom="$6" paddingHorizontal={isExpanded ? '$12' : '$0'} gap="$4">
          {menuItems}
        </YStack>
      </YStack>
    </YStack>
  );
};
