import React, {useEffect, useState} from 'react';

import {Platform} from 'react-native';

import {
  Dialog,
  Button,
  Typography,
  View,
  XStack,
  YStack,
  ShellTrans,
  SectionLoader,
  useIsAuthenticated,
  useAppConfig,
} from '@medad-sep/core';

export const InactiveSession = () => {
  if (Platform.OS !== 'web') {
    return null;
  }

  const isAuthenticated = useIsAuthenticated();
  const app = useAppConfig();

  if (!isAuthenticated || !app.inactiveSession?.enabled) {
    return null;
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [countdown, setCountdown] = useState(60);
  let inactivityTimer;

  const sessionTimeout = app.inactiveSession?.duration;
  const inactivityLimit = sessionTimeout
    ? sessionTimeout * 60 * 1000
    : app.inactiveSession?.duration
    ? app.inactiveSession.duration * 60 * 1000
    : 0;

  const openDialog = () => {
    setIsDialogOpen(true);
    setCountdown(60);
  };

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(openDialog, inactivityLimit);
  };

  useEffect(() => {
    if (inactivityLimit <= 0) return;

    resetInactivityTimer();

    const handleUserActivity = () => {
      resetInactivityTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [inactivityLimit]);

  useEffect(() => {
    let countdownTimer;
    if (isDialogOpen) {
      countdownTimer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            onAccept();
            clearInterval(countdownTimer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdownTimer);
  }, [isDialogOpen]);

  const onCancel = () => {
    setIsDialogOpen(false);
  };

  const onAccept = () => {
    setIsLoggingOut(true);
  };

  if (isLoggingOut) {
    return <SectionLoader overlayMode isLoading />;
  }

  return (
    <View style={{padding: 20}}>
      <Dialog
        open={isDialogOpen}
        header={
          <View justifyContent="center">
            <Typography>
              <ShellTrans i18nKey="timeLimit.warningTitle" />
            </Typography>
          </View>
        }
        footer={
          <XStack gap="$5" justifyContent="center">
            <Button onPress={onCancel}>
              <ShellTrans i18nKey="timeLimit.cancel" />
            </Button>
            <Button onPress={onAccept}>
              <ShellTrans i18nKey="timeLimit.logout" />
            </Button>
          </XStack>
        }
        modelHeight="30%"
        title="Warning!"
        content={
          <YStack justifyContent="center" gap="$5">
            <Typography>
              <ShellTrans i18nKey="timeLimit.warningMsg" />
            </Typography>
            <Typography fontWeight="700">{countdown > 0 ? `${countdown} seconds remaining` : null}</Typography>
          </YStack>
        }
      />
    </View>
  );
};
