export const en = {
  home: 'Home',
  profiles: 'Profiles',
  default: 'Default',
  settings: {
    title: 'Settings',
    notice: 'Notice',
    restartNow: 'Restart Now',
    switchNow: 'Switch Now',
    setDefault: 'Set Default',
    switchAccount: 'Switch Account',
    logOut: 'Log Out',
    later: 'Later',
    category: {
      app: 'App setting',
      service: 'Services Setting',
      help: 'Help & support',
      privacy: 'Privacy',
    },
    connectWithUs: 'Connect With Us',
    privacyPolicy: 'Privacy policy',
    scheduleSetting: 'My Schedule',
    termsAndConditions: 'Terms And Conditions',
    language: 'Language',
    darkMode: 'Dark Mode',
    userProfile: {
      title: 'User account',
      userName: 'User name',
      email: 'Email',
      firstName: 'First name',
      lastName: 'last name',
    },
    _: '// todo remove and move to applet-core: editHomeWidget, calendar, myTools, dashboard',
    editHomeWidget: {
      label: 'Edit Home Widget',
      title: 'Select and arrange widgets that you want to appear at home page',
    },
    calendar: {
      syncEvents: 'Synchronize calendar events',
      displayEvents: 'Display calendar events',
    },
  },
  timeLimit: {
    cancel: 'Cancel',
    logout: 'Log Out',
    warningTitle: 'Session Timeout Warning',
    warningMsg: 'Your session will expire soon. Would you like to stay logged in?',
  },
  myTools: {
    title: 'My Tools',
    category: 'Category',
    allCategories: 'All categories',
    noTools: 'No tools',
    servicesList: 'Services List',
    searchByApplicationName: 'Search by application name',
    openInWebView: 'Open in web view',
    openInNewTab: 'Open in new tab',
  },
  dashboard: {
    save: 'Save',
    personalize: 'Personalize',
    insertRow: 'Insert new row',
    addWidget: 'Add widget',
    allWidgets: 'All widgets',
  },
  launcher: {
    sideBarTitle: 'MY SERVICES',
    home: {
      title: 'Home',
    },
    notifications: {
      title: 'Notifications',
    },
    _: '// todo remove and move to applet-core: editHomeWidget, calendar, myTools, dashboard',
    dashboard: 'Dashboard',
    allServices: {
      searchInput: 'Search for a service ...',
      title: 'All services',
      numOfServices: 'Number of services {{count}}',
    },
    calendar: {
      title: 'Calendar',
    },
  },
  seeMore: 'See more',
  hi: 'Hi',
};
